@import "~bootstrap/scss/bootstrap";

// colors
$pretty--color-default: #bdc3c7 !default;
$pretty--color-primary: #8C8E92 !default;
$pretty--color-info: #5bc0de !default;
$pretty--color-success: #5cb85c !default;
$pretty--color-warning: #f0ad4e !default;
$pretty--color-danger: #d9534f !default;
$pretty--color-dark: #5a656b !default;

$fa-font-path: "~font-awesome/fonts";
@import "~font-awesome/scss/font-awesome";
@import '~pretty-checkbox/src/pretty-checkbox.scss';

@font-face {
  font-family: 'DIN-Regular';
  font-style: normal;
  font-weight: normal;
  src: local('DIN-Regular'), url('../fonts/DINR____.woff') format('woff');
}

$pretty--class-name: pretty !default;


