.navbar{
  min-height: 100px;
  border-radius: 0;
  margin-bottom: 25px;
  border: 2px solid #708090;
}

.navbar-default .navbar-brand {
  height: 102px;
  padding: 5px;
  border-radius: 15px;
}

.navbar-default .navbar-brand img {
  max-height:90px;
  max-width:200px;
}

.navbar-default .nav .navbar-nav{
  min-height: 100px;
}

.navbar-default .navbar-nav > li > a{
  font-size: medium;
  padding-top: 0;
  padding-bottom: 0;
  line-height: 100px;
}

.main-menu-nav-link{
  color: white !important;
}

.main-menu-nav-link:hover{
  color: black !important;
}

.annee-traitement {
  font-size: xx-large !important;
  color: white;
  right: 150px;
  position: absolute;
}

.linear-gradient{
  background: linear-gradient(to right, white 10%, #6e7f80 25%, #708090);
}