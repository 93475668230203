body {
    background-color: snow;
}

.flex-centered{
    align-items: center;
    justify-content: center;
}

.btn-round{
    display: inline-block;
    text-decoration: none;
    font-weight: bold;
    width: 200px;
    height: 200px;
    line-height: 200px;
    border-radius: 50%;
    text-align: center;
    vertical-align: middle;
    overflow: hidden;
    transition: .4s;
    margin: 15px;
}

.btn-round-green{
    background: #8cd460;
    color: rgba(255, 255, 255, 0.8);
    box-shadow: 0 0 0 5px #8cd460;
    border: solid 2px rgba(255, 255, 255, 0.8);
}

.btn-round-blue{
    background: #1c91c2;
    color: rgba(255, 255, 255, 0.8);
    box-shadow: 0 0 0 5px #1c91c2;
    border: solid 2px rgba(255, 255, 255, 0.8);
}

.btn-round:hover{
    border-style: dashed;
}

.btn-square{
    position: relative;
    display: inline-block;
    font-weight: bold;
    padding: 0.5em 1em;
    text-decoration: none;
    transition: .4s;
    text-transform: uppercase;
    font-family: Calibri;
    font-size: large;
}

.btn-square-blue{
    background-color: snow;
    border-left: solid 4px #007AA5;
    border-right: solid 4px #007AA5;
    color: #007AA5;
}

.btn-square-blue:hover {
    background: #007AA5;
    color: #FFF;
}

.btn-square-red{
    background-color: snow;
    border-left: solid 4px #FF554E;
    border-right: solid 4px #FF554E;
    color: #FF554E;
}

.w-45{
    width: 45%;
}

.btn-square-red:hover {
    background: #FF554E;
    color: #FFF;
}

.pointer {
    cursor: pointer;
}

.max-w-75 {
    max-width: 75% !important;
}

.max-w-90 {
    max-width: 90% !important;
}

.condition{
    margin-top: 10px;
    margin-bottom: 10px;
    margin-right: -15px;
    margin-left: -15px;
    padding: 10px 0 10px 0;
    border: 1px solid white;
    border-radius: 10px;
    background: rgb(0, 130, 255);
    color: white;
}

.statut-label
{
    font-size: x-large;
    font-weight: bold;
    padding: 10px;
    background: rgba(0, 123, 255, 1);
    color: #fff;
    border-radius: 18px;
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.2);
}