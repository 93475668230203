.timeline
{
  position: relative;
  box-sizing: border-box;
  min-width: 95%;
}

.timeline:before
{
  content: '';
  position: absolute;
  left: 50%;
  width: 2px;
  height: 100%;
  background: #c5c5c5;
}

.timeline ul
{
  margin: 0;
  padding: 0;
}

.timeline ul li
{
  list-style: none;
  position: relative;
  width: 50%;
  padding: 20px 40px;
  box-sizing: border-box;
}

.timeline ul li.left
{
  float: left;
  text-align: right;
  clear: both;
}

.timeline ul li.right
{
  float: right;
  text-align: left;
  clear: both;
}

.content
{
  padding-bottom: 20px;
}

.timeline ul li.left:before
{
  content: '';
  position: absolute;
  top: 24px;
  right: -6px;
  width: 10px;
  height: 10px;
  background: rgb(233,33,99);
  border-radius: 50%;
  box-shadow: 0 0 0 3px rgba(233,33,99,.2);
}

.timeline ul li.right:before
{
  content: '';
  position: absolute;
  top: 24px;
  left: -4px;
  width: 10px;
  height: 10px;
  background: rgb(233,33,99);
  border-radius: 50%;
  box-shadow: 0 0 0 3px rgba(233,33,99,.2);
}

.timeline ul li h3
{
  margin: 0;
  padding: 0;
  font-weight: 600;
  color: rgb(233,33,99);
}

.timeline ul li p
{
  margin: 10px 0 0;
  padding: 0;
}

.timeline ul li .time h4
{
  margin: 0;
  padding: 0;
  font-size: 14px;
}

.timeline ul li.left .time
{
  position: absolute;
  top: 12px;
  right: -120px;
  margin: 0;
  padding: 8px 16px;
  background: rgb(233,33,99);
  color: #fff;
  border-radius: 18px;
  box-shadow: 0 0 0 3px rgba(233,33,99,.2);
}

.timeline ul li.right .time
{
  position: absolute;
  top: 12px;
  left: -120px;
  margin: 0;
  padding: 8px 16px;
  background: rgb(233,33,99);
  color: #fff;
  border-radius: 18px;
  box-shadow: 0 0 0 3px rgba(233,33,99,.2);
}